.testimonial{
    padding:40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h2{
        font-size: 26px;
        color:$grey;
        margin-bottom: 10px;

        span{
            font-weight: 400;
        }
    }

    .border{
        border-bottom: 2px solid $blue;
        width:15%;
        margin-bottom: 30px;
    }

    p{
        color:$grey;
        line-height: 25px;
        span{
            font-weight: 700;
            display: block;
        }
    }

    @include breakpoint(mobilelandscape) {

        p{
            max-width: 60%;
        }

    }

    @include breakpoint(phablet) {
        padding:60px;
        
        p{
            max-width: 60%;
        }

    }

    @include breakpoint(tablet) {
        padding:80px;

        h2{
            font-size: 32px;
        }

        p{
            font-size: 18px;
        }

    }
    
    @include breakpoint(laptop) {
        padding:100px;

        h2{
            font-size: 40px;
        }

        .border{
            margin-bottom: 60px;
        }

        p{
            line-height: 40px;
            font-size: 20px;
            max-width: 40%;
        }

    }

}