.neocbb{
    padding: 20px;
    background:$white;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_banner{
        box-shadow: 1px 3px 4px 3px rgba(111, 111, 110, 0.2);
        border-radius: 30px;
        margin-bottom: 40px;

        &_section{
            padding: 20px 30px;

            &:nth-child(1){
                background:$blue;
                border-radius: 30px;
                color:$white;
                font-weight: 700;
                box-shadow: 1px 3px 4px 3px rgba(111, 111, 110, 0.2);

            }
            &:nth-child(2){
             background:$white;
             border-radius: 30px;
   

             img{
                margin:0 auto;
                &:nth-child(1){
                    margin-bottom: 20px;
                    max-width: 200px;

                }
                &:nth-child(2){
                    margin-bottom: 20px;
                    max-width: 130px;
                }
             }
            }
        }
    }

    &_description{

        &_title{
            margin: 20px 0;
            color:$grey;
        }

        &_list{
            display: grid;
            grid-template-columns: repeat(1,1fr);

            li{
                margin-bottom: 20px;

                img{
                    margin-bottom: 10px;
                    height: 80px;
                    width: auto;
                }

                p{
                    color:$grey;
                    margin-bottom: 10px;

                    span{
                        font-weight: 700;
                    }
                }
            }
        }
    }


    .items {
        margin-bottom: 40px;

        li {
            margin-bottom: 20px;
        }
    }

    @include breakpoint(mobilelandscape){

        &_banner{
            display: grid;
            grid-template-columns: 2fr 1fr;

            &_section{
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
            }
        }

        &_description{

            &_list{
                grid-template-columns: repeat(2,1fr);
                column-gap: 20px;
            }
        }

        .items {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;

            li {
                margin-bottom: 0px;
            }
        }
    }

    @include breakpoint(phablet) {
        padding:40px;
        
        &_banner{
            display: grid;
            grid-template-columns: 2fr 1fr;

            &_section{
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: center;
            }
        }

        &_description{

            &_title{
                margin: 30px 0;
            }

            &_list{
                grid-template-columns: repeat(2,1fr);
                column-gap: 20px;
            }

        }

        .items {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;

            li {
                margin-bottom: 0px;
            }
        }
    }

    @include breakpoint(tablet) {
        padding: 60px;

        &_banner{

            &_section{

                &:nth-child(2){
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                    flex-wrap: nowrap;

                    img{
                        margin:0;
                        &:nth-child(1){
                            max-width: 50%;
                            margin-bottom: 0;
                        }
                        &:nth-child(2){
                            max-width: 25%;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        &_description{

            &_title{
                margin: 40px 0;
            }

            &_list{
                column-gap: 40px;
            }
        }
    }

    @include breakpoint(laptop) {
        padding:80px;

        &_banner { 
         
            &_section {
         
                &:nth-child(1){
                    font-size: 20px;
                    padding:30px 10%;
                }
            }
        }

        &_description{
            max-width: 1024px;
            padding:80px;
            margin:0 auto;

            &_title{
                margin: 50px auto;
                font-size: 20px;
            }

            &_list{
                column-gap: 50px;
                margin:0 auto;

                li{
                    margin-bottom: 40px;
                }
            }
        }
    }

    @include breakpoint(desktop) {
        
        &_description{
            max-width: 1200px;

            &_title{
                margin: 50px auto;
            }

            &_list{
                column-gap: 60px;
            }
        }
    }

    @include breakpoint(bigdesktop) {
        //padding:100px;

        &_banner{
         
            &_section{
         
                &:nth-child(1){
                    font-size: 22px;
                }
            }
        }

        &_description{
            padding: 100px 140px;

            max-width: 1440px;

            &_title{
                margin: 50px auto;
            }

            &_list{
                column-gap: 80px;
            }
        }
    }
}
