.enjoy{
    background-image: linear-gradient(
        rgba(27,34,46,.7), 
        rgba(27,34,46,.7)
        ), url('../img/efective_mb.jpg');
    background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.pregnant{
        background-image:none;
        background-color: $blue-light;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
    }

    &_banner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;

        h2{
            color:$white;
            font-size: 26px;
            margin-bottom: 10px;

            span{
                font-weight: 400;
            }
        }

        .border{
			border-bottom: 2px solid $white;
			width:15%;
            margin: 0 auto 20px;
		}

        p{
            color: $white;
            line-height: 25px;
            margin-bottom: 30px;
            font-size: 18px;
            span{
                font-weight: 700;
            }
        }

        .button__rayen{
            //color:$white;
            margin: 0 auto 0;

            span{
                //color: $white;
            }
        }
    }

    @include breakpoint(mobilelandscape) {

        background-image: linear-gradient(
            rgba(27,34,46,.7), 
            rgba(27,34,46,.7)
            ), url('../img/efective_ipad.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;

        &_banner{

            padding:20px;
        }
	}

    @include breakpoint(phablet) {
        background-image:  linear-gradient(
            rgba(27,34,46,.7), 
            rgba(27,34,46,.7)
            ),url('../img/efective_ipad.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
        min-height: 30vh;

        &_banner{

            padding:40px;
        }
	}
    
    @include breakpoint(tablet) {
        background-image:  linear-gradient(
            rgba(27,34,46,.7), 
            rgba(27,34,46,.7)
            ),url('../img/efective_desk.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
        min-height: 30vh;

        &_banner{
            max-width: 800px;
            padding:60px;

            h2 {
                font-size: 32px;
                span{
                    display: block;
                }
            }

            p {
                //padding-left: 20%;
                font-size: 18px;
            }
        }
	}

    @include breakpoint(laptop) {
        min-height: 70vh;

        &_banner{
            max-width: 1024px;
            padding:80px;

            h2 {
                font-size: 40px;
            }

            p {
                font-size: 20px;
            }
        }

        &.pregnant { 
            min-height: 50vh;
        }
	}

    @include breakpoint(desktop) {

        &_banner{
            max-width: 1200px;
        }
	}

    @include breakpoint(bigdesktop) {
        
        &_banner{
            max-width: 1440px;
        }
	}

}