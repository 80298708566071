.slider{
    min-height: 60vh;

    &_main{

        .swiper-wrapper{
            .swiper-slide {

                padding:20px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center bottom;

                &:nth-child(1){
                    background-image: url('../img/Banner1_mb.jpg');
                }

                &:nth-child(2){
                    background-image: url('../img/Banner2_mb.jpg');
                }
                &:nth-child(3){
                    background-image: url('../img/Banner3_mb.jpg');
                }

                &:nth-child(4){
                    background-image: url('../img/Banner4_mb.jpg');
                }
                &:nth-child(5){
                    background-image: url('../img/Banner5_mb.jpg');
                }

                &:nth-child(6){
                    background-image: url('../img/Banner6_mb.jpg');
                }


            }
        }

        .swiper-pagination{
            display: flex;
            flex-direction: column;
            height: 100%;
            left:90% !important;
            justify-content: center;

            & span{
                margin-bottom: 10px !important;
                width: 16px;
                height: 16px;
            }

            & span.swiper-pagination-bullet-active.swiper-pagination-bullet{
                background:$grey;
            }

            & span.swiper-pagination-bullet{
                background:$white;
                opacity: 0.8;
            }
        }

    }

    @include breakpoint(mobilelandscape){

        &_main{

            .swiper-wrapper{
                .swiper-slide {


                    &:nth-child(1){
                        background-image: url('../img/banner-1.jpg');
                        background-position: right bottom;
                    }
    
                    &:nth-child(2){
                        background-image: url('../img/banner-2.jpg');
                        background-position: right bottom;
                    }
                    &:nth-child(3){
                        background-image: url('../img/banner-3.jpg');
                        background-position: right bottom;
                    }
    
                    &:nth-child(4){
                        background-image: url('../img/banner-4.jpg');
                        background-position: right bottom;
                    }
                    &:nth-child(5){
                        background-image: url('../img/banner-5.jpg');
                        background-position: right bottom;
                    }
    
                    &:nth-child(6){
                        background-image: url('../img/banner-6.jpg');
                        background-position: right bottom;
                    }
       
                }
            }

            .swiper-pagination{
                left: 95% !important;
            }
        }

    }

    @include breakpoint(phablet){
      
        &_main{

            .swiper-wrapper{
                .swiper-slide {

                    padding: 40px;
                    padding-right: 40%;
                    
                    &:nth-child(1){
                        background-image: url('../img/banner-1.jpg');
                        background-position: right bottom;
                    }
    
                    &:nth-child(2){
                        background-image: url('../img/banner-2.jpg');
                        background-position: right bottom;
                    }
                    &:nth-child(3){
                        background-image: url('../img/banner-3.jpg');
                        background-position: right bottom;
                    }
    
                    &:nth-child(4){
                        background-image: url('../img/banner-4.jpg');
                        background-position: right bottom;
                    }
                    &:nth-child(5){
                        background-image: url('../img/banner-5.jpg');
                        background-position: right bottom;
                    }
    
                    &:nth-child(6){
                        background-image: url('../img/banner-6.jpg');
                        background-position: right bottom;
                    }
       
                }
            }

            .swiper-pagination{
                left: 95% !important;
            }
        }

    }

    @include breakpoint (tablet){
      
        &_main{

            .swiper-wrapper{
                .swiper-slide {

                    padding: 60px;
                    padding-right: 40%;
                
                    .content{

                        h2{
                            font-size: 32px;
                            //margin-bottom: 5px;
                        }
                        h3{
                            font-size: 28px;
                            //margin-bottom: 10px;
                        }

                        p{
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint (laptop){
    
        &_main{

            .swiper-wrapper{
                .swiper-slide {

                    padding: 80px;
                    padding-right: 40%;


                    .content{
                        h2{
                            font-size: 40px;
                            margin-bottom: 10px;
                        }
                        h3{
                            font-size: 32px;
                            margin-bottom: 20px;
                        }
                    
                        p{
                            font-size: 20px;
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }

    }

    @include breakpoint (bigdesktop){


    }


}