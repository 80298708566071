// Fonts
$font-base:	 'Poppins', sans-serif;
$font-title:  'Poppins', sans-serif;

// Colors
$white: #fff;
$black: #1b222e; // darkblue
$blue: #009ee2;
$dark-blue: #006d96;
$blue-light: #5dc4ec;
$grey: #6f6f6e;
$grey-light: #f2f2f2;

