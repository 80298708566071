.about{
    padding:20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img{
        margin-bottom: 20px;
    }

    &_description{

        h2{
            color: $grey;
            margin-bottom: 10px;
        }

		.border{
			border-bottom: 2px solid $blue;
			width:15%;
            margin-bottom: 20px;
		}

        p{
            color:$grey;
            margin-bottom: 10px;
            font-weight: 300;
        }
    }

    @include breakpoint(phablet) {
        padding:40px;

        &_description{

            h2{
                font-size: 32px;
            }
        }
    }
    @include breakpoint(tablet) {
        padding:60px;
        display:grid;
        grid-template-columns: repeat(2,1fr);
        align-items: start;
        margin: 0 auto;
        
        &_description{
            padding:0px 30px 20px;
            align-self: start;
        }
    }

    @include breakpoint(laptop) {
        max-width: 1024px;
        padding: 80px;

        &_description{
            padding:0px 0px 20px 30px;
            //padding: 0px 60px 20px;

            h2{
                font-size: 42px;
            }

            .border{
                margin-bottom: 40px;
            }
        }
    }
    @include breakpoint(desktop) {
        max-width: 1200px;
    }
    
    @include breakpoint(bigdesktop) {
        padding: 100px 140px;
        max-width: 1440px;

        &_description{

            padding: 0px 0px 20px 80px;

            h2{
                font-size: 44px;
            }
        }
    }
}
