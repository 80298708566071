.hero {
	background-image: 
		linear-gradient( to top, $black, rgba(0, 109, 150, .2) ),
		url('../img/slide-02-m.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;
	
	h2 {
		color: $white;
		font-size: 28px;
		font-weight: 700;
	}

	p {
		color:$white;
		font-weight: 400;
		padding-bottom: 20px;
	}

	.border {
		border-bottom: 2px solid $white;
		width:15%;
		margin:10px auto 10px;
	}

	&.banner {
		padding: 40px 20px;
		background-image:
			linear-gradient( to bottom, $black, rgba(0, 109, 150, .2) ),
			url('../img/banner-m.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	.top-content {
		position: relative;
		z-index: 2;
		padding: 105px 30px 30px;
		background: linear-gradient(to top, $black 30%, transparent 50%);
	}

	&_title {
		text-align: center;
		margin: 0 auto;
		padding: 30px 0;
	}

	form {
		margin: 0 auto;
		padding: 20px 0;

		label {
			color:$white;
		}
		.button{
			display: block;
			margin:20px auto 0;
			padding:10px 20px;
		}
	}

	.gracias & {
		background-image: linear-gradient(rgba(27,34,46,.7),rgba(27,34,46,.7)),url(../img/efective_mb.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center bottom;	
		min-height: 75vh;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 70px 20px 20px;
	}

	@include breakpoint(mobilelandscape) {

		.top-content {
			background: linear-gradient(to top, $black 40%, transparent 60%);
		}

		form {
			display: grid;
			grid-template-columns: repeat(2,1fr);
			gap: 0 20px;
		}
	}

	@include breakpoint(phablet) {
		background-image: 
			linear-gradient( to top, rgba(0, 34, 82, .9), rgba(0, 34, 82, .2) ),
			url('../img/slide-02.jpg');

		.top-content {
			background: none;
		}

		h2 {
			font-size: 36px;

			span {
				display: block;
			}
		}

		p {
			font-size: 18px;
		}

		form {
			margin: 0 auto;
			max-width: 80%;
			display: grid;
			grid-template-columns: repeat(2,1fr);
			gap: 0 20px;

			.button {
				grid-column: 1 / 3;
				margin: auto;
				width: 320px;
			}
		}
	}

	@include breakpoint(tablet) {
		height: 75vh;

		.top-content {
			height: 75vh;
			padding-top: 125px;
			display: flex;
			align-content: center;
			flex-wrap: wrap;
			pointer-events: none;
		}	

		form {
			display: grid;
			grid-template-columns: repeat(4,1fr);
			gap: 20px;
			align-items: end;
			padding: 0;
			max-width: 1100px;

			.button, input, select {
				margin: 0;
				pointer-events: all;
			}			

			.button {
				grid-column: 1 / 5;
				justify-self: center;
				width: 320px;
			}
		}

		&.banner {
			background-image: 
				linear-gradient( to bottom, $black, rgba(0, 109, 150, .2) ),
				url('../img/banner.jpg');
			padding: 80px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			flex-direction: column;
		}
	}
	@include breakpoint(laptop) {

		&_title {
			margin-bottom: 0;

			h2{
				font-size: 44px;
			}

			.border {
				width: 10%;
			}
		}

		form {
			bottom: 42px;
		}

		.gracias & {
			padding: 85px 0 0;	
			background-image: linear-gradient(rgba(27,34,46,.7),rgba(27,34,46,.7)),url(../img/efective_desk.jpg); 
		} 
	}

	@include breakpoint(bigdesktop) {
		
		&_title {
			max-width: 1400px;

			h2{
				font-size: 54px;
			}
			p{
				font-size: 20px;
			}
		}

		form {
			label {
				font-size: 18px;
			}
		}
	}
}