.footer {
	background: $grey-light;
	padding: 40px 30px;
	color: $grey;

	h2 {
		font-size: 22px;
		margin-bottom: 36px;
	}

	h3 {
		font-size: 18px;
		margin-bottom: 12px;
	}

	.countries {

		li {
			margin-bottom: 22px;
		}
	}

	a {
		color: $grey;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	@include breakpoint(phablet) {
		padding: 40px;

		.countries {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 20px;

			li {
				margin-bottom: 0px;
			}
		}
	}
	@include breakpoint(tablet) {
		padding: 60px;
		display: flex;
		
		h2 {
			padding-right: 120px;
		}

		.countries {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 20px;

			li {
				margin-bottom: 0px;
			}
		}
	}
	@include breakpoint(laptop) {
		padding: 80px;
		display: flex;
		
		h2 {
			// padding-right: 120px;
		}

		.countries {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	@include breakpoint(laptop) {
		// padding: 80px;
		
		h2 {
			padding-right: 240px;
		}
	}

	&_burakko {
		padding: 30px 20px;
		text-align: center;
		background: $blue;
		font-size: 14px;

		a {
			color:$grey-light;
			transition: all 0.2s linear;
			
			&:visited{
				text-decoration: none;
				color:$grey-light;
			}
			&:hover{
				color:$white;
			}
		}
	}		
}