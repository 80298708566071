.contacto{
    padding: 20px;

    form {
        margin-bottom: 30px;
        box-shadow: 2px 2px 4px 2px rgba(111, 111, 110, 0.2);
        border-radius: 10px;
        padding: 20px;

        input, textarea, select {
            background-color: $grey-light;
        }

        h2 {
            font-size: 24px;
        }

        p {
            font-weight: 300;
            margin-bottom: 30px;
        }

        .border {
			border-bottom: 2px solid $blue;
			width:15%;
            margin: 22px 0;
		}

        .button{
            background-color: $blue;
            box-shadow: 1px 3px 4px 3px rgba(111, 111, 110, 0.2);
            width: 220px;
            margin: 0 auto;
            display: block;

            &:hover {
                background-color:$white;
            }
        }
    }

    @include breakpoint(phablet) {
        padding: 40px;

        form {
            padding: 40px;                   
            max-width: 820px;
            margin: auto;
        }
    }

    @include breakpoint(tablet) {
        padding: 60px;

        form {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;

            .top {
                grid-column:  1 / 3;

                p {
                    margin: 0;
                }
            }

            input, textarea {
                margin: 0;
            }

            .content:last-of-type {
                grid-column:  1 / 3;
            }

            .button {
                grid-column:  1 / 3;
            }
        }
    }

    @include breakpoint(laptop) {
        padding:100px;
        column-gap: 80px;
        
        form{
            padding:60px;
            h2{
                font-size: 32px;
            }
        }

        &_social{
            iframe{
                margin-bottom: 50px;
                height: 500px;
            }

            h3{
                font-size: 32px;
            }

            .border{
                margin-bottom: 30px;
            }

            ul li svg:hover{
                fill:$dark-blue;
            }
        }
    }

    @include breakpoint(bigdesktop) {

        padding:140px;

        form{
            h2{
                font-size: 36px;
            }
        }

        &_social{

            h3{
                font-size: 36px;
            }
        }
    }
}