.test{
    background-color: $white;
    padding: 40px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h2 {
        font-size: 26px;
        color:$grey;
        margin-bottom: 20px;
    }

   .border{
        border-bottom: 2px solid $blue;
        width:15%;
        margin-bottom: 26px;
    }

    p {
        color:$grey;
        margin-bottom: 20px;
    }

    ul {
        // display: grid;
        grid-template-columns: repeat(2,1fr);
        row-gap: 10px;
        column-gap: 10px;
        margin: 0 auto;

        li {
            max-height: 280px;
            min-width: 260px;
            box-shadow: 2px 2px 4px 2px rgba(111, 111, 110, 0.2);
            border-radius: 10px;
            padding:10px;
            margin-bottom: 20px;
            
            &.active {
                background-color: $blue;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                figure {
                    display: none;
                }

                p {
                    display: block;
                    text-align: center;
                    color: $white;
                    font-size: 14px;
                    margin-bottom: 0;
                }
            }
            
            p {
                display: none;
                max-width: 150px;
            }

            figure {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                
                img {
                    padding-bottom: 30px;
                    max-height: 100px;
                    width: auto;
                }

                figcaption {
                    color:$grey;
                    line-height: 1.4em;
                    font-weight: 700;
                }
            }
        }
    }

   @include breakpoint(mobilelandscape) {

        ul {
            margin:0 auto;
            grid-template-columns: repeat(3, 1fr);
        }
   }

   @include breakpoint(phablet) {
        padding:40px;

        ul {
            display: grid;
            margin: 0 auto;
            grid-template-columns: repeat(3, 1fr);

            li {
                min-width: auto;
                
                p { max-width: none; }
            }
        }

        p {
            margin-bottom: 40px;
        }
   }

   @include breakpoint(tablet) {
        padding: 60px 0;
        max-width: 800px;
        margin: 0 auto;

        h2 {
            font-size: 32px;
        }

        ul {
            width: 100%;
            
            li {
                height: 250px;
                margin-bottom: 0;
                width: 100%;
                display: flex;

                figure {
                    max-width: 180px;
                    margin: auto;
                }
            }
        }
    }

    @include breakpoint(laptop) {
        padding:80px;
        max-width: 1024px;

        h2{
            font-size: 40px;
        }
        p{
            font-size: 20px;
            margin-bottom: 60px;
        }

        ul {
            gap: 20px;

            li {
                min-height: 350px;

                &:hover {
                    background-color: $blue;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
        
                    figure {
                        display: none;
                    }
        
                    p {
                        display: block;
                        text-align: center;
                        color: $white;
                        font-size: 16px;
                        margin-bottom: 0;
        
                    }
                }

                &.active {

                    p {
                        font-size: 16px;
                    }
                }

                p {
                    font-size: 16px;
                }

                figure {
                    max-width: 200px;

                    figcaption {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    @include breakpoint(desktop) {
        max-width: 1200px;
    }

    @include breakpoint(bigdesktop) {
        max-width: 1440px;

    }
}