.difference{
    background-color: $white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
 
    h2 {
        font-size: 26px;
        color:$grey;
        margin-bottom: 10px; 
    }
 
    .border {
        border-bottom: 2px solid $blue;
        width:15%;
        margin-bottom: 20px;
    }
 
    p {
        color:$grey;
        margin-bottom: 20px;
    }
    
    &_list {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        column-gap: 20px;

        li {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            text-align: center;
            margin-bottom: 20px;

            img{
                max-width: 70px;
            }
            h3 {
                font-size: 16px;
                color: $blue;
                margin-bottom: 10px;

            }
        }
    }
 
     /* Style the buttons that are used to open and close the accordion panel */
    .accordion {
      background-color: $grey-light;
      border-radius: 20px;
      font-weight: 700;
      color: $grey;
      cursor: pointer;
      padding: 18px;
      width: 100%;
      text-align: left;
      border: none;
      outline: none;
      transition: 0.4s;
      margin-bottom: 10px;

      &::after{
        content: '\02795'; /* Unicode character for "plus" sign (+) */
        font-size: 13px;
        color: $grey;
        float: left;
        margin-right: 5px;
      }

      &.active::after{
        content: "\2796"; /* Unicode character for "minus" sign (-) */
    }

    }

    /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
    .active, .accordion:hover {
      background-color: #ccc;
    }

    /* Style the accordion panel. Note: hidden by default */
    .panel {
      padding: 0 18px;
      background-color: white;
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.2s ease-out;
    } 


 
    @include breakpoint(mobilelandscape) {
 
        &_list{
            grid-template-columns: repeat(3,1fr);   
        }

    }
 
    @include breakpoint(phablet) {
        padding:40px;

        p {
            margin-bottom: 40px;
        }

        &_list {
            grid-template-columns: repeat(3,1fr);   
        }
    }
 
    @include breakpoint(tablet) {
        padding: 60px;
        max-width: 800px;
        margin: 0 auto;

        h2 {
            font-size: 32px;
        }

        .border{
            margin-bottom: 30px;
        }

        &_list{
            grid-template-columns: repeat(4,1fr);   
        }
    }

    @include breakpoint(laptop) {
        padding:80px;
        max-width: 1024px;

        h2 {
            font-size: 40px;
        }
        p {
            font-size: 18px;
            margin-bottom: 60px;
        }

        .border {
            margin-bottom: 40px;
        }
        
        &_list {
            grid-template-columns: repeat(5,1fr);   
            column-gap: 40px;

            li {
                margin-bottom: 30px;

                img {
                    margin-bottom: 20px;
                }
            }
        } 
    }

    @include breakpoint(desktop) {
        max-width: 1200px;
    }

    @include breakpoint(bigdesktop) {
        max-width: 1440px;

    }
 }