.location{
    background-color: $white;
    padding:20px;
    display:flex;
    justify-content: center;
    align-items: center;
    
    .bullets{
        width: 100%;
        background: linear-gradient(to right, $blue,$dark-blue);
        border-radius: 20px;
        padding: 20px;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        box-shadow: 2px 2px 4px 2px rgba(111, 111, 110, 0.2);

        li{
            display: flex;
            flex-wrap: nowrap;
            margin-bottom: 10px;
            align-items: center;
            margin-right: 10px;

            img{
                width: 25px;
                margin-right: 10px;
            }

            h3{
                font-size: 16px;
                font-weight: 400;
                color: $white;

                span{
                    display: block;
                    font-weight: 700;
                }
            }
        }

    }

    @include breakpoint(mobilelandscape) {

        .bullets{
            grid-template-columns: repeat(2,1fr);
        }
    }

    @include breakpoint(phablet) {
        height: 25vh;
        .bullets{
            grid-template-columns: repeat(2,1fr);
        }
    }

    @include breakpoint(tablet) {
        padding: 0px 3%;
        height: 25vh;

        .bullets{
            grid-template-columns: repeat(4,1fr);
            padding:20px 5%;

            li{
                

                img{
                    margin-right: 20px;

                    &:nth-child(1){
                        height: 25px;
                        width: auto;
                    }
                }
            }
        }
    }

    @include breakpoint(laptop) {
        //padding: 0px 60px;

        .bullets{
            //padding:20px 60px;

            li{
                
                img{
                    margin-right: 20px;
                    width: 30px;

                    &:nth-child(1){
                        height: 30px;
                        width: auto;
                    }
                }

                h3{
                    font-size: 18px;
                }
            }
        }

    }

    @include breakpoint(bigdesktop) {

        .bullets li h3{
            font-size: 20px;
        }

    }


}