.header {
	display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
	position: absolute;
	z-index: 10;
	padding: 20px;
	background-color: $white;

	.logo {
		width: auto;
		height: 35px;
		display: block;
	}

	&__toggle {
	    width: 27px;
	    
	    .gracias & {
	    	display: none;
	    }
	}

	&__toggle-line {
	    display: block;
	    background: $grey;
	    width: 100%;
	    height: 4px;
	    margin-bottom: 4px;
	    box-shadow: 2px 2px 4px rgba(0,0,0,.4);
	    opacity: 1; 
	    transition: opacity 600ms ease 200ms, top 300ms ease, left 300ms ease, background 300ms ease, transform 300ms ease 450ms;    
		
		&:nth-child(1) {
		    transform-origin: center;
		}
		&:nth-child(3) {
		    transform-origin: center;
		}
	}    

	&.is-active {
	    position: fixed;
	    z-index: 11;
		
		.header__toggle-line {

			&:nth-child(1) {
			    transform: translateY(8px) rotate(45deg);
			    background: $white;
			}
			&:nth-child(2) {
			    background: $white;
			    opacity: 0;
			}
			&:nth-child(3) {
			    transform: translateY(-8px) rotate(-45deg);
			    background: $white;
			}
		}
	}

	&.fixed {
	    padding: 20px 18px;
	    position: fixed;
	    animation: headerFix .5s ease-out;
	    background: $white;
	    align-items: center;

		.header__toggle-line {
			background: $grey;
		}
	}	

	@include breakpoint(tablet) {
		padding: 20px 40px;
		align-items: center;
		z-index: 3;

		&__toggle {
		    display: none;
		}		

		.logo {
			z-index: 3;
			height: 52px;
 
			.gracias & {
		    	display: block;
		    }
		}

		&.fixed {
			position: absolute;
		}

		&.is-active {
		    position: absolute;
		    background: $white;
		    z-index: 10;
		}
	}

	@include breakpoint(laptop) {
		padding: 20px 60px;

		.logo {
			//width: 170px;
		}
	}

	@include breakpoint(bigdesktop) {
		padding: 20px 80px;
	}
}

section.menu {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 11;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding-top: 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    background: $black;
    transition: opacity 400ms ease, left 100ms ease 600ms;
	
	&.is-visible {
	    left: 0;
	    opacity: 1;
	    transition: left 100ms ease, opacity 400ms ease 200ms;
	    overflow: hidden;
	}
	
	.menu-inner {
	    margin: 0;
		text-align: center;
		font-family: $font-title;
		letter-spacing: 0em;
		
		.menu-list {
		    list-style: none;
		    margin: 0;
		    padding: 0;
		    
			li {
				font-size: 20px;
				padding: 5px 0px;   
			    // line-height: 1.6;
			    margin-bottom: 22px;
			    
				span {
				    line-height: 38px;
				}
				a {
				    line-height: 38px;
				    color: $white;
				}
			}
		}

	}

	.gracias & { display: none;}

	@include breakpoint(tablet) {
		position: absolute;
		left: 0;
		top: 26px;
		z-index: 11;
		opacity: 1;
		background: transparent;
		padding: 0px 40px;
		height: auto; 
		align-items: flex-end;
		
		.menu-inner {
			display: flex;
			
			.menu-list {
			    display: flex;
			    
				li {
				    font-size: 16px;   
				    line-height: 1.6;
				    margin: 0;
					
					&.inicio { display: block}

					span {
					    line-height: 1;
					}
					a {
					    line-height: 32px;
					    color: $grey;
					    padding: 0 10px 5px;
					    transition: color 400ms ease;
						display: block;
					    
					    &:hover {
							border-bottom: 2px solid $blue;
					    }

					    .fixed & {
							color: $white;
					    }
					}

				}
			}

		}

		&.fixed {
		    top: 0;
			padding: 13px 40px;
			background: $white url('../img/logo.svg') no-repeat 20px center;
			background-position-x: 40px;
			background-size: 320px;
			position: fixed;
			animation: headerFix .5s ease-out;

			.menu-inner .menu-list a{
				//color:$white;
			}
		}
	}
	@include breakpoint(laptop) {	
		padding: 0px 60px;

		&.fixed {
			padding: 13px 60px;
			background-position-x: 60px;
		}

		.menu-inner {
			
			.menu-list {
			    
				li {

					a {
					    //padding: 0 28px !important;
						margin:0 28px;
						padding: 0;
					}
				}
			}
		}
	}
	@include breakpoint(bigdesktop) {
		padding: 0px 80px;

		&.fixed {
			padding: 13px 80px;
			background-position-x: 80px;
		}
	}
}

@keyframes headerFix {
  0%   { top: -100px; }
  100% { top: 0; }
}